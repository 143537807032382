<template>
  <div class="bbb-pages">
    <div class="bbb-how-to">
      <router-link :to="url_next" class="close-button">
        <img src="/static/img/big-bang-boom/close-bbb-store.svg" width="100" />
      </router-link>
      <ul class="vn">
        <li class="comet">
          <strong>+3 POINTS</strong>
          <!-- 3x tap to destroy -->
        </li>
        <li class="asteroid">
          <strong>+2 POINTS</strong>
          <!-- 2x tap to destroy -->
        </li>
        <li class="meteoroid">
          <strong>+1 POINT</strong>
          <!-- 1x tap to destroy -->
        </li>
        <li class="star">
          <strong>-2 POINTS</strong>
          Do not tap!
        </li>
      </ul>
      <br /><br />
      <img src="/static/img/big-bang-boom/how-to-play-bbb-store.png" width="431" height="38" class="title" />
      <ol>
        <li>Hancurkan meteor yang muncul di galaxy dalam waktu <strong>15 detik</strong></li>
        <li>
          Masing-masing meteor memiliki <strong>POIN</strong> untuk dikumpulkan. Hati-hati, star
          <strong>tidak boleh</strong> di-tap karena akan <strong>mengurangi</strong> jumlah poin yang sudah kamu
          kumpulkan.
        </li>
        <li>Kamu akan mendapatkan <strong>freebies</strong> bila mengumpulkan diatas <strong>50 poin</strong>.</li>
      </ol>
      <router-link to="/soco-vending-machine-event/bbb-game-start" tag="button">Main Sekarang</router-link>
    </div>
  </div>
</template>

<script>
import VendingMachineTracker from '@/mixins/vending-machine-tracker';

export default {
  name: 'PimplePopHowToPlay',
  mixins: [VendingMachineTracker],
  computed: {
    isVnSource() {
      return this.$route.query.isvn;
    },
    url_next() {
      return '/soco-vending-machine-event/home';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bbb-how-to {
  min-height: 100vh;
  background: #000 url('/static/img/big-bang-boom/bg-bbb-3-store.png') no-repeat top center;
  background-size: 100% auto;
  position: relative;
}
.vn-bg {
  min-height: 100vh;
  background: #000 url('/static/img/big-bang-boom/bg-bbb-3-store-vn.png') no-repeat top center;
  background-size: 100% auto;
  position: relative;
}
ul {
  display: block;
  margin: 0;
  padding: 0;
  height: 1181px;
  position: relative;
}
ul li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font: normal 28px/39px 'brandontext-regular', sans-serif;
  text-align: center;
  color: #fff;
  width: 270px;
  position: absolute;
}
ul li strong {
  display: block;
  font: normal 40px/32px 'brandontext-bold', sans-serif;
  letter-spacing: 2.6px;
  color: #d51d6e;
  margin: 0 0 4px;
}
.comet {
  top: 240px;
  left: 301px;
}
.asteroid {
  top: 484px;
  left: 519px;
}
.meteoroid {
  top: 762px;
  left: 290px;
}
.star {
  top: 1024px;
  left: 470px;
}
.comet-vn {
  top: 220px;
  left: 301px;
}
.asteroid-vn {
  top: 464px;
  left: 500px;
}
.meteoroid-vn {
  top: 740px;
  left: 290px;
}
.title {
  margin: 0 auto 57px;
  display: block;
}
ol {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 880px;
}
ol li {
  min-height: 74px;
  margin: 0 0 22px;
  list-style: none;
  padding: 0 0 0 90px;
  font: normal 30px/36px 'brandontext-regular', sans-serif;
  position: relative;
  color: #fff;
}
ol li:before {
  content: '';
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0;
  left: 0;
  background-size: cover !important;
}
ol li strong {
  font-family: 'brandontext-bold', sans-serif;
  color: #eb395f;
}
ol li:nth-child(1):before {
  background: transparent url('/static/img/big-bang-boom/howto-number-1-bbb-store.png') no-repeat center;
}
ol li:nth-child(2):before {
  background: transparent url('/static/img/big-bang-boom/howto-number-2-bbb-store.png') no-repeat center;
}
ol li:nth-child(3):before {
  background: transparent url('/static/img/big-bang-boom/howto-number-3-bbb-store.png') no-repeat center;
}
button {
  display: block;
  width: 685px;
  height: 98px;
  margin: 50px auto 60px;
  border-radius: 48px;
  font: normal 32px 'brandontext-bold', sans-serif;
  letter-spacing: 3.2px;
  color: #fff;
  background: #d51d6e;
  border: 0;
}
button:focus {
  outline: none;
}
.close-button {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 2;
}
.title-vn {
  font: normal 52px/28px 'SVN-dm-serif-display-regular', sans-serif;
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
}
.strong-vn {
  font-family: 'SVN-brandontext-regular', sans-serif;
  color: #eb395f;
  font-weight: normal;
}
.btn-vn {
  font-family: 'SVN-brandontext-regular', sans-serif;
  text-transform: uppercase;
  margin: 30px auto 0px;
}
ul.vn li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font: normal 28px/39px 'SVN-brandontext-bold', sans-serif;
  text-align: center;
  color: #fff;
  width: 290px;
  position: absolute;
}
</style>
